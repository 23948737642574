require('./bootstrap');

window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2');
window.dropify = require('dropify');
window.select2 = require('select2');

$(document).on("keyup", ".select2-search__field", function (event) {
    if (event.ctrlKey || event.metaKey) {
        var element = $(this).closest('.select2-container').prev();
        if (event.key == 'a') {
            element.find("option").each(function (i, option) {
                $(option).prop('selected', 'selected');
            });
            element.trigger('change');
        }
    }
});
